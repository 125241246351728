<template>
  <div class="authentication-component h-100 w-100 overflow-auto position-fixed">
    <div class="container h-100 d-flex align-items-center">
      <div class="authentication-component-inner m-auto">
        <div class="header_content mt-5 mb-5 text-center clear">
          <div class="logo_block">
            <a class="active">
              <img width="200" :src="getSiteDetails.login_logo" alt="replug">
            </a>
          </div>

        </div>
        <div class="white_box m-auto w-75">
          <div class="box_inner">
            <div class="box_head d-flex align-items-center justify-content-center">
              <div class="left">
                <h2>Check your email!</h2>
              </div>

            </div>
            <div class="box_content p-5">

              <div class="text-center">
                <p style="font-size: 18px;">We have sent you a verification email to your email address.
                  <span class="email font-weight-bold">{{ getProfile.email }}</span>
                  Click and follow the link inside it.</p>
              </div>
              `
              <div class="btn_block text-center mt-4">
                <button class="btn---cta btn-blue btn-round   "
                        @click.prevent="resendVerificationEmail()">
                  <span>Resend</span>
                </button>
                <a @click="$router.push('update_email')" class="btn---cta cursor_pointer light-blue btn-round  ">
                  <span>Try with another account</span>
                </a>
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import http from '@/mixins/http-lib'
import { getWhitelabelDetailsFromDomain } from '@/config/config'
import { userTypes } from '@/state/modules/mutation-types'

export default {
  async created () {
    await this.fetchWhitelabelAppSettings()
  },

  data: function () {
    return {}
  },
  computed: {
    ...mapGetters(['getProfile'])
  },
  methods: {
    ...mapActions(['fetchWhitelabelAppSettings'])
  }
}
</script>

<style scoped>

</style>
